
.page_menu_touch_icon {
  display: block;
  margin: 10px auto;
}

.page__bottom {
  position: absolute;
  z-index: 10;
  width: 100%;
  bottom: 0;
}

.page__top {
  position: relative;
  z-index: 10;
}

.page_hidden {
  display: none;
}

.page_item-2 {
	overflow-y: scroll;
    height: 100%;
}

.page__bottom-1{
  background-color: $icon-color;
}
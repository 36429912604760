
.button {
  height: 40px;
  background-color: $primary-color;
  border-radius: 3px;
  margin: auto;
  text-decoration: none;
}

.button_area {
  margin: 20px auto;
}

.button_responsive {
  width: 100%;
  display: flex;
}

.button_area.button_responsive {
  width: calc(100% - 80px);
}

.button__icon {
  position: absolute;
  width: 16px;
  margin: 0 15px;
  margin-top: 8px;
}

.button__text {
  line-height: 40px;
  color: $background-color;
  text-transform: uppercase;
  font-size: 18px;
  margin: auto;
  font-weight: bold;
}

.button_dark {
  background-color: $special-color-2;
}

.button_space {
  margin-top: 15px;
}

.title {
  color: $primary-color;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  margin: 5px 0;
  text-transform: uppercase;
}

.title_sm {
  color: $background-color;
  font-size: 18px;
}

.subtitle {
  color: $subtitle-color;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.page_menu_subtitle_highlight {
  color: $primary-color;
}

.heading_lg {
  width: 100%;
  color: $background-color;
  text-transform: uppercase;
  text-align: center;
}

.heading__first {
  display: block;
}

.heading__highlight {
  color: $primary-color;
}
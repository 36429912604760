
.navigation-bar {
  background-color: $icon-color;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10px;
}

.navigation-bar_hidden {
  display: none;
}

.navigation-bar__overlay {
  position: fixed;
  bottom: 10;
  width: 100%;
  height: 40px;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
}

.navigation-bar__progress {
  background-color: $primary-color;
  float: left;
  width: 100%;
  height: 10px;
  transform: translateX(-100%);
  transition: all 0.3s;
}

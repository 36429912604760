
.video_fullpage {
  position: absolute;
  bottom: 0;
  top: 60px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.video__source {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
}

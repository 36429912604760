
.navigation {
  position: absolute;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #eee;
  text-align: center;
  background: #fcfcfc;
}

.navigation__item {
  height: 100%;
}

.navigation__item_icon {
  width: 60px;
  line-height: 60px;
  vertical-align: middle;
  padding: 18px;
}

.navigation__item_hidden {
  visibility: hidden;
}

.navigation__item_left {
  float: left;
}

.navigation__item_middle {
  width: calc(100% - 120px);
  display: inline-block;
}

.navigation__item_right {
  float: right;
}

.navigation__link {
  display: inline-block;
}

.navigation__logo {
  height: 100%;
  width: auto;
}


$primary-color: #83CF42;

$icon-color: #D8D8D8;

$text-color: #3E3E3E;

$background-color: #FEFEFE;

$subtitle-color: #787878;

$message-color: #0682FF;

$screen-color: #EFF3FF;

$special-color: #F8B32C;

$special-color-2: #DB2022;
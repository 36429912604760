
.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu__items {
  flex-grow: 1;
  position: relative;
}

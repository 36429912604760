
.carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 70px;
  overflow: hidden;

  @include screen-sm {
    padding: 30px 90px;
  }
}

.carousel__arrows {
  display: none;

  @include screen-sm {
    display: block;
  }
}

.carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(50% - 200px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
}

.carousel__arrow_left {
  left: 40px;
  background-image: url(/img/previous.svg);
  background-position: left;
}

.carousel__arrow_right {
  right: 40px;
  background-image: url(/img/next.svg);
  background-position: right;
}

.carousel__slides {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  transition: all 0.3s;
}

.carousel__item {
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
  transition: all 0.3s;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.carousel__item_1 {
  background-image: url(../img/cookie.svg);
}

.carousel__item_2 {
  background-image: url(../img/smartphone.svg);
}

.carousel__item_3 {
  background-image: url(../img/jean.svg);
}

.carousel__item_4 {
  background-image: url(../img/icons/locked-padlock-grey.svg);
}

.carousel__item_active {
  transform: scale(1);
  z-index: 10;
}
